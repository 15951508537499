/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeviceCard from '@Components/DeviceCard';
import { Row, Col } from 'reactstrap';
import useHook from './hook';
// import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  content: { flexGrow: 1, padding: theme.spacing(3) },
}));
const ShowMultipleDevice = ({ devices }) => devices.map((device, idy) => <Col xl="3" lg="6" md="6" sm="12" className='mb-3'><DeviceCard device={device} index={idy} /></Col>);
const Devices = (props) => {
  const classes = useStyles();
  const h = useHook(props);
  
//   const sensorTypeBy = _.chain(h.sensors).groupBy('type').map((device) => device[0]).value();
  return (
    <div className={classes.content}>
      <Row><ShowMultipleDevice devices={h.devicegroups} /></Row>
    </div>
  );
};

export default Devices;
