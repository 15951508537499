import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default function HighlightTabs({
  tab, setTab, items, customStyle,
}) {
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Paper elevation={0}>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        style={{ ...customStyle }}
      >
        {items.map(({ label, value }) => <Tab label={label} value={value} style={{ ...customStyle }} />)}
      </Tabs>
    </Paper>
  );
}
