/* eslint-disable no-shadow */
import React, { useState } from 'react';
import {
  Modal,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Input,
} from 'reactstrap';
import { AllImages } from '@Assets/Images';

const AddDeviceModal = ({ modal, toggle }) => {
  const clients = ['Syafiq', 'Nazim', 'Bin', 'Ismail'];
  const types = ['Lux', 'Humidity', 'Ph', 'Npk'];

  const [dropdownOpen, setOpen] = useState(false);
  const [dropdownOpen2, setOpen2] = useState(false);
  const [client, setClient] = useState('');
  const [type, setType] = useState('');

  const toggleDropdown = () => setOpen(!dropdownOpen);
  const toggleDropdown2 = () => setOpen2(!dropdownOpen2);

  const submit = () => {
    alert('close');
    toggle();
  };
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="p-5 bg-white rounded"
      style={{ marginTop: 100 }}
    >
      <div
        className="position-absolute h5 px-4 py-2"
        style={{ right: -40, top: -30, cursor: 'pointer' }}
        onClick={toggle}
      >
        X
      </div>
      <div className="h3">Add Device</div>
      <Row className="align-items-center my-2">
        <Col xl="4">
          <div className="h6">Client</div>
        </Col>
        <Col xl="8">
          <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="w-100"
          >
            <DropdownToggle
              caret
              color="white"
              className={
                client === ''
                  ? 'shadow rounded border-dark justify-content-end d-flex p-3'
                  : 'shadow rounded border-dark d-flex justify-content-between align-items-center pl-3'
              }
              style={{ border: '1px solid grey' }}
            >
              {client}
            </DropdownToggle>
            <DropdownMenu className="w-100">
              {clients.map((client) => (
                <DropdownItem onClick={() => setClient(client)}>
                  {client}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
      </Row>
      <Row className="align-items-center my-2">
        <Col xl="4">
          <div className="h6">Sensor type</div>
        </Col>
        <Col xl="8">
          <ButtonDropdown
            isOpen={dropdownOpen2}
            toggle={toggleDropdown2}
            className="w-100"
          >
            <DropdownToggle
              caret
              color="white"
              className={
                type === ''
                  ? 'shadow rounded border-dark justify-content-end d-flex p-3'
                  : 'shadow rounded border-dark d-flex justify-content-between align-items-center pl-3'
              }
            >
              {type}
            </DropdownToggle>
            <DropdownMenu className="w-100">
              {types.map((type) => (
                <DropdownItem onClick={() => setType(`${type} Sensor`)}>
                  <div className="d-flex align-items-center">
                    <img
                      src={AllImages(type).default}
                      alt={`${type} Sensor`}
                      style={{ height: 25, width: 30 }}
                    />
                    <div className="ml-2">{type} Sensor</div>
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
      </Row>
      <Row className="align-items-center my-2">
        <Col xl="4">
          <div className="h6">Sub Location</div>
        </Col>
        <Col xl="8">
          <Input
            className="border-dark shadow"
            placeholder="eg: Greenhouse A, Building A"
          />
        </Col>
      </Row>
      <Row className="align-items-center my-2">
        <Col xl="4">
          <div className="h6">State</div>
        </Col>
        <Col xl="8">
          <Input
            className="border-dark shadow"
            placeholder="eg: Selangor, Johor, Melaka"
          />
        </Col>
      </Row>
      <Row className="align-items-center my-2">
        <Col xl="4">
          <div className="h6">Coordinate</div>
        </Col>
        <Col xl="8">
          <Button className="w-100 bg-white text-primary border-primary">
            Select Coordinate
          </Button>
        </Col>
      </Row>
      <Row className="align-items-center my-2">
        <Col xl="4" />
        <Col xl="8">
          <Button className="bg-primary px-5" onClick={submit}>
            Confirm
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddDeviceModal;
