import React from 'react';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Button } from 'reactstrap';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import logo_raise from '@Assets/Images/LOGO-OFO-TECH.png';
import { useParams } from 'react-router-dom';

const drawerWidth = 100;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: '#F2F5FD',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Topbar = (props) => {
  const classes = useStyles();
  const { id: DevId } = useParams();
  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar>
        <IconButton
          color="black"
          aria-label="open drawer"
          edge="start"
          onClick={props.handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <div className="d-flex">
          {DevId && <img src={logo_raise} alt="OFO TECH" />}
          <Typography variant="h6" noWrap className="text-dark ml-3 d-flex align-items-center">
            Devices
          </Typography>
          <Button className="bg-primary ml-4" onClick={props.toggle}>
            + Add device
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
