import React from 'react';
import Maps from '@Components/Map';
import SensorCard from '@Components/SensorCard';

export default function DeviceDashboard({ info, Sensors, SensorsData }) {
  return (
    <div>
      <div className="shadow" style={{ width: '100%', height: '50vh' }}>
        <Maps zoom={info.mapzoom} lat={info.lat} lng={info.lng} markers={[info, ...Sensors.Devices]} />
      </div>
      {Sensors.Devices?.map((sensor, idx) => (
          <SensorCard sensor={sensor} data={SensorsData[sensor.uuid]} />
      ))}
    </div>
  );
}
