/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable max-nested-callbacks */
import { useState, useEffect } from 'react';
import Api, { endpoints } from '@Helpers/api';

export default (props) => {
    const [devicegroups, setDeviceGroups] = useState([]);
    const createDevice = () => {
        Api({
            endpoint: endpoints.registerDeviceGroup(),
            data: { name: 'xx', OrganizationId: 1 },
            onSuccess: (res) => {
                console.log('vv resp', res)
            },
          });
    }
    useEffect(() => {
        console.log('trigger useEffect ');
        
        Api({
            endpoint: endpoints.getDeviceGroup(),
            onSuccess: (res) => setDeviceGroups(res.data),
          });
    }, []);

    return { 
        createDevice,
        devicegroups, 
    };
};
