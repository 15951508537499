/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-shadow */
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Form, FormGroup } from 'reactstrap';
import Logo from '@Assets/Images/ofosense_logo.svg';
import { PersonOutlineOutlined, LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import useHook from './LoginHook';

const __DEV__ = process.env.NODE_ENV === 'development';

const LoginForm = (props) => {
  const [show_password, set_show_password] = React.useState(false);
  const h = useHook(props);
  console.log('vv loginform', h)
  if (h.token) return <Redirect to={'/devices'} />;


  return (
    <div className="vw-100 vh-100 d-flex justify-content-start align-items-center login-bg">
      <div className="d-flex align-items-center flex-column" style={{ marginLeft: '12%' }}>
        <img src={Logo} style={{ width: 160, height: 160, marginBottom: 20 }} />
        <div className="mb-5 h1 text-white">OFOSENSE</div>
        <Form>
          <FormGroup
            style={{ width: '25vw', marginBottom: 30 }}
            className="d-flex justify-content-start align-items-center"
          >
            <PersonOutlineOutlined style={{ color: 'white' }} fontSize="large" />
            <input
              onKeyDown={h.onKeyDown}
              className="input-style"
              type="email"
              name="email"
              id="exampleEmail"
              placeholder="Username"
              value={h.email}
              onChange={e => h.setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup
            style={{ width: '25vw', marginBottom: 30 }}
            className="d-flex justify-content-start align-items-center"
          >
            <LockOutlined style={{ color: 'white' }} fontSize="large" />
            <div className="position-relative" style={{ width: '85%' }}>
              <input
                onKeyDown={h.onKeyDown}
                className="input-style"
                type={show_password ? 'text' : 'password'}
                name="password"
                id="examplePassword"
                placeholder="Password"
                value={h.password}
                onChange={e => h.setPassword(e.target.value)}
              />
              {show_password
                ? (
                  <Visibility
                    style={{ color: '#F5F5F599', position: 'absolute', right: 0, top: 0 }}
                    onClick={() => set_show_password(!show_password)}
                  />
                )
                : (
                  <VisibilityOff
                    style={{ color: '#F5F5F599', position: 'absolute', right: 0, top: 0 }}
                    onClick={() => set_show_password(!show_password)}
                  />
                )}
            </div>
          </FormGroup>
          <div className="d-flex flex-column align-items-center">
            <Button
              className="mb-3 px-5"
              style={{ backgroundColor: '#FF9E01', fontWeight: 'bold' }}
              onClick={() => h.attemptLoginOrPasswordReset()}
              disabled={h.isLoading}
            >
              SIGN IN
            </Button>
            <div className="text-white">
              Don&apos;t have an account?
              <Link to="/register" className="text-white ml-2">
                Sign Up &gt;
              </Link>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
