import React from 'react';

const ResetPasswordForm = () => {
  return (
    <div
      className="vw-100 vh-100 d-flex justify-content-center align-items-center"
      style={{ backgroundColor: '#c1cce2' }}
    >
      This is reset
    </div>
  );
};

export default ResetPasswordForm;
