import React, { useState } from 'react';
import useHook from './hook';
import { Card, Grid } from '@material-ui/core';
import SideMenu from './components/SideMenuDevice';
import DeviceDashboard from './components/DeviceDashboard';
import Timeline from './components/Timeline';
import DeviceInfo from './components/DeviceInfo';
import HighlightTabs from '@Components/HighlightTabs';

const tabslist = [
  { label: 'Dashboard', value: 'dashboard' },
  { label: 'Timeline', value: 'timeline' },
  { label: 'Device Info', value: 'device-info' },
];

export default function DeviceDetails(props) {
  const h = useHook(props);
  const [tab, setTab] = useState('timeline');
  const DeviceTab = () => {
    switch (tab) {
      case 'dashboard':
        return <DeviceDashboard {...h} />;
      case 'timeline':
        return <Timeline {...h} />;
      case 'device-info':
        return <DeviceInfo {...h} />;
      default:
        return (
          'default'
        );
    }
  };
  return (
    <Grid container>
      <Grid xs={2}>
        <SideMenu selected={h.selectedSensors} setSelected={h.setSelectedSensors} devices={h.Sensors.Devices} list={h.LineData} />
      </Grid>
      <Grid xs={10}>
        <Card className="p-4 shadow">
          <HighlightTabs items={tabslist} tab={tab} setTab={setTab} />
          <Grid item xs={12} style={{ padding: 20 }}>
            <DeviceTab />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
