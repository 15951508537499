/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-shadow */
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Form, FormGroup } from 'reactstrap';
import Logo from '@Assets/Images/ofosense_logo.svg';
import { PersonOutlineOutlined, LockOutlined, VisibilityOff, Visibility } from '@material-ui/icons';
import firebase from '@Components/firebase';
import { AuthContext } from '@Context/Auth';

const RegisterForm = () => {
  const { user } = React.useContext(AuthContext);
  const [email, set_email] = React.useState('');
  const [password, set_password] = React.useState('');
  const [show_password, set_show_password] = React.useState(false);

  const SignUp = (email, password) => {
    firebase.auth().createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      console.log(userCredential);
      // Signed in
      // var user = userCredential.user;
      // ...
    })
    .catch((error) => {
      console.log(error);
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // ..
    });
  };
  return user ? <Redirect to={{ pathname: '/dashboard' }} /> : (
    <div className="vw-100 vh-100 d-flex justify-content-start align-items-center login-bg">
      <div className="d-flex align-items-center flex-column" style={{ marginLeft: '12%' }}>
        <img src={Logo} style={{ width: 160, height: 160, marginBottom: 20 }} />
        <div className="mb-5 h1 text-white">OFOSENSE</div>
        <Form>
          <FormGroup
            style={{ width: '25vw', marginBottom: 30 }}
            className="d-flex justify-content-start align-items-center"
          >
            <PersonOutlineOutlined style={{ color: 'white' }} fontSize="large" />
            <input
              className="input-style"
              type="email"
              name="email"
              id="exampleEmail"
              placeholder="Username"
              value={email}
              onChange={(e) => { set_email(e.target.value); }}
            />
          </FormGroup>
          <FormGroup
            style={{ width: '25vw', marginBottom: 30 }}
            className="d-flex justify-content-start align-items-center"
          >
            <LockOutlined style={{ color: 'white' }} fontSize="large" />
            <div className="position-relative" style={{ width: '85%' }}>
              <input
                className="input-style"
                type={show_password ? 'text' : 'password'}
                name="password"
                id="examplePassword"
                placeholder="Password"
                value={password}
                onChange={(e) => set_password(e.target.value)}
              />
              {show_password
              ? (
                <Visibility
                  style={{ color: '#F5F5F599', position: 'absolute', right: 0, top: 0 }}
                  onClick={() => set_show_password(!show_password)}
                />
                )
              : (
                <VisibilityOff
                  style={{ color: '#F5F5F599', position: 'absolute', right: 0, top: 0 }}
                  onClick={() => set_show_password(!show_password)}
                />
                )}
            </div>
          </FormGroup>
          <div className="d-flex flex-column align-items-center">
            <Button
              className="mb-3 px-5"
              style={{ backgroundColor: '#FF9E01', fontWeight: 'bold' }}
              onClick={() => SignUp(email, password)}
            >
              SIGN UP
            </Button>
            <div className="text-white">
              Got an account?
              <Link to="/" className="text-white ml-2">
                Sign In &gt;
              </Link>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RegisterForm;
