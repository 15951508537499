/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import { AllImages } from '@Assets/Images';
import moment from 'moment';

export default ({ device, index }) => (
  <Card className="rounded-lg shadow position-relative d-flex align-items-stretch h-100">
      <CardBody>
        <div className="d-flex" style={{ height: 120 }}>
          <div className="w-50 d-flex justify-content-center align-items-center">
            <img
              // src={AllImages(device.type).default}
              src={device.image || AllImages(device.type).default}
              alt={`${device.type} Sensor`}
              style={{ height: 100, width: 100 }}
            />
          </div>
          <div className="w-50">
            <p className="m-0">SEN-{device.id}</p>
            <div className="h6 my-1">{device.name || 'Sensor'}</div>
            <p className="text-muted m-0">
              measures {device.type || 'data'} in its environment
            </p>
          </div>
        </div>
        <div className="d-flex py-2">
          <div className="w-50 p-1">
            <p className="m-0">Last Connection</p>
            <div className="h6">{device.last_connection || moment().format('DD-MM-YYYY hh:mm:ss A')}</div>
          </div>
          <div className="w-50 p-1">
            <p className="m-0">Location</p>
            <div className="h6">{device.location || 'N/A'}</div>
          </div>
        </div>
        <Link to={`/devices/${device.id}`}>
          <Button className="w-100 rounded-pill bg-primary">
            View details
          </Button>
        </Link>
      </CardBody>
      <div
        className="position-absolute"
        style={{ top: -10, right: -20, zIndex: 10 }}
      >
        <div
          className="position-relative rounded-circle shadow-lg"
          style={{
            width: 70,
            height: 70,
            backgroundColor: index % 2 === 0 ? '#EF373733' : '#00BF9133',
          }}
        >
          <div
            className="position-absolute rounded-circle shadow-lg"
            style={{
              width: 50,
              height: 50,
              left: '50%',
              marginLeft: -25,
              top: '50%',
              marginTop: -25,
              backgroundColor: index % 2 === 0 ? '#EF3737' : '#00BF91',
            }}
          />
        </div>
      </div>
    </Card>
);
