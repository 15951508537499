import { Grid, InputAdornment, List, ListItem, ListItemIcon, ListItemText, TextField } from '@material-ui/core';
import { ChevronLeft, FilterList, Search } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { AllImages } from '@Assets/Images';
import { useState } from 'react';

const StyledList = styled(List)({
  '&& .Mui-selected': { boxShadow: '0 0 16px 0 #03030a1a' },
});

export default function SideMenu({ selected, setSelected, devices }) {
  return (
    <div>
      <Grid>
        <Link to={`/devices`}><ChevronLeft />Back</Link>
      </Grid>
      <Grid>
        <TextField
          className="mr-3"
          placeholder="Search"
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: (<InputAdornment><Search /></InputAdornment>),
          }}
        />
      </Grid>
      <Grid container justify="space-between" style={{ marginTop: '2rem', width: '90%' }}>
        <Grid item className="align-items-center">
          {devices?.length} Device(s)
        </Grid>
        <Grid item><FilterList /></Grid>
      </Grid>
      <Grid item style={{ marginTop: 15 }}>
        <StyledList style={{ height: '80vh', overflow: 'auto', paddingRight: 10 }}>
          {devices?.map((device, idx) => <Item device={device} idx={idx} selected={selected} />)}
        </StyledList>
      </Grid>
    </div>
  );
}

const Item = ({ device, idx, selected }) => {
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => { setHover(!hover); };
  const handleMouseLeave = () => { setHover(!hover); };
  return (
    <ListItem
      selected={selected === device.id}
      style={{ margin: 2, padding: 10, transform: hover && 'scale(1.1)', boxShadow: hover && '0 0 16px 0 #03030a1a' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ListItemIcon style={{ marginRight: 5 }}>
        <img
          src={AllImages(device.id).default}
          alt={`${device.id} Sensor`}
          style={{ height: 25, width: 25 }}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <div>
            <div
              className="position-absolute rounded-circle shadow"
              style={{ width: 10, height: 10, top: 21, backgroundColor: idx % 2 === 0 ? '#EF3737' : '#00BF91' }}
            />
            <p style={{ marginLeft: 20, marginBottom: 0 }}>{device.name}</p>
          </div>
        }
      />
    </ListItem>
  )
}