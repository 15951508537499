/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { database } from '@Components/firebase';
import { useParams } from 'react-router-dom';

export default (props) => {
  const { id: DevGroupId } = useParams();
  const [info, set_info] = useState({
      mapzoom: 14,
      lat: 3.140853,
      lng: 101.693207,
      state: 'Kuala Lumpur',
      location: 'GreenHouse A',
  });
  const [Sensors, setSensors] = useState([]);
  const [SensorsData, setSensorsData] = useState({});
  const [selectedSensors, setSelectedSensors] = useState(0);
  useEffect(() => {
    setSensorsData({});
      if (Sensors.Devices) {
        Sensors.Devices.forEach(sensor => {
          database.ref(`Sensor/${sensor.uuid}/data`).on('value', (snap) => setSensorsData(v => ({ ...v, [sensor.uuid]: snap.val() })));
        });
      }
  }, [Sensors]);
  
    useEffect(() => {
      Api({
          endpoint: endpoints.getSensors(DevGroupId),
          onSuccess: (res) => setSensors(res.data),
        });
  }, []);
  return {
    info,
    DevGroupId,
    Sensors,
    SensorsData,
    selectedSensors, 
    setSelectedSensors,
  };
};
