/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-undef */
import { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '@Context/Auth';
import Api, { endpoints } from '@Helpers/api';

export default (props) => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  const { token } = useContext(AuthContext);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const toggleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword);
    props.setLoginError();
  };

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') attemptLoginOrPasswordReset();
  };

  const attemptLoginOrPasswordReset = () => {
    if (!email) return props.setLoginError('Email required');
    if (!isForgotPassword) return props.login({ email, password });
    props.setIsLoading(true);
    Api({
      endpoint: endpoints.requestTokenToResetPassword(),
      data: { email, redirect_url: `${window.location.origin}/reset-password` },
      onSuccess: () => {
        props.setIsLoading(false);
        toast('success', 'Reset password successful. Please check your email to proceed');
        setIsForgotPassword(false);
      },
      onFail: () => {
        props.setIsLoading(false);
        toast('error', 'Failed to reset password');
      },
    });
  };

  return {
    onKeyDown,
    attemptLoginOrPasswordReset,
    toggleForgotPassword,
    token,
    from,
    email,
    setEmail,
    password,
    setPassword,
  };
};
