import React from 'react';
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import {
  Dashboard,
  SettingsOutlined,
  AccountCircle,
  Devices,
} from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Logo from '@Assets/Images/ofosense_logo.svg';
import firebase from '@Components/firebase';

const drawerWidth = 100;

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'black',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  // necessary for content to be below app bar
  drawerPaper: {
    width: drawerWidth,
  },
  selected: {
    backgroundColor: '#F2F5FD !important',
    color: '#266DF7 !important',
  },
}));

const Sidebar = (props) => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const path = location.pathname.slice(1);

  const sidebarTop = [
    {
      name: null,
      icon: <img src={Logo} alt="Logo" style={{ width: 35 }} />,
    },
    {
      name: 'dashboard',
      icon: <Dashboard fontSize="large" />,
    },
    {
      name: 'devices',
      icon: <Devices fontSize="large" />,
    },
  ];

  const sidebarBottom = [
    {
      name: 'settings',
      icon: <SettingsOutlined fontSize="large" />,
    },
    {
      name: null,
      icon: <AccountCircle fontSize="large" />,
    },
  ];

  const drawer = (
    <List style={{ position: 'relative', height: '100vh' }}>
      <div>
        {sidebarTop.map((data) => {
          const selected = data.name === path
          return (
            <Link to={`/${data.name}`}>
              <ListItem
                button
                key={data}
                style={{ flexDirection: 'column' }}
                selected={selected}
                classes={{ selected: classes.selected, root: classes.root }}
              >
                {!data.name ? (
                  data.icon
                ) : (
                  <ListItemIcon
                    style={{
                      justifyContent: 'center',
                      color: selected ? '#266DF7' : 'black',
                    }}
                    classes={{ root: classes.root }}
                  >
                    {data.icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  className="text-capitalize"
                  primary={selected ? data.name : ''}
                />
              </ListItem>
            </Link>
          )
        })}
      </div>
      <div style={{ position: 'absolute', bottom: 2 }}>
        {sidebarBottom.map((data) => {
          const selected = data.name === path
          return (
            <Link to="/logout">
              <ListItem button key={data} style={{ flexDirection: 'column' }}>
                <ListItemIcon style={{ justifyContent: 'center' }}>
                  {data.icon}
                </ListItemIcon>
                <ListItemText
                  className="text-capitalize"
                  primary={selected ? data.name : ''}
                />
              </ListItem>
            </Link>
          )
        })}
      </div>
    </List>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default Sidebar;
