export const Lux = require('./lux_sensor.png');
export const Humidity = require('./humidity_sensor.png');
export const Npk = require('./npk_sensor.png');
export const Ph = require('./ph_sensor.png');
export const ImageNotFound = require('./image-not-found.png');

export const AllImages = (CODE) => {
    switch (CODE) {
        case 'Lux': return Lux;
        case 'Humidity': return Humidity;
        case 'NPK': return Npk;
        case 'Ph': return Ph;
        default: return ImageNotFound;
    }
};
