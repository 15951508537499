import React, { useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';

export default function Timeline() {
  const [date, setDate] = useState('latest');
  const handleDate = (e, newDate) => { setDate(newDate) };
  return (
    <div style={{ width: '100%' }}>
      <Tabs value={date} indicatorColor="primary" textColor="primary" onChange={handleDate}>
        {[
          { title: 'Latest', value: 'latest' },
          { title: 'Last Hour', value: '1h' },
          { title: '6 Hours', value: '6h' },
          { title: '1 Day', value: '1d' },
          { title: '1 Week', value: '1w' },
        ].map(e => (
          <Tab value={e.value} label={e.title} style={{ border: '0.5px solid #000000' }} />
        ))}
      </Tabs>
      <br />
      {date}
    </div>
  );
}
