import React, { useState } from 'react';
import { CssBaseline, Grid } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import AddDeviceModal from '@Components/AddDeviceModal';
import Sidebar from '@Components/Sidebar';
import Topbar from '@Components/Topbar';

import 'react-toastify/dist/ReactToastify.css';

const Layout = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [modal, setModal] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const toggle = () => setModal(!modal);

  return (
    <Grid
      className="content"
      style={{
        position: 'fixed', top: '50px', left: '0px', right: '0px', bottom: '0px', overflow: 'auto',
      }}
    >
      <AddDeviceModal modal={modal} toggle={toggle} />
      <ToastContainer />
      <CssBaseline />
      <Topbar
        // {...props}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        toggle={toggle}
      />
      <Sidebar
        // {...props}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />
      <Grid item xs={12} style={{ position: 'absolute', width: '96vw', paddingLeft: '4rem' }}>
        {props.child}
      </Grid>
    </Grid>
  );
};

export default Layout;
