import React from 'react';
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { Grid } from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

export default function GaugeChart({ currentValue, min, max, unit }) {
  const classes = useStyles();
  const maths = (Number(currentValue) / (Number(max) + Number(min))) * 100;
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Grid container className={classes.sizeGauge} style={{ position: 'relative', margin: '20px 0', width: '12.5rem' }} >
        <Grid item><SemiCircleProgressBar percentage={maths} stroke={'red'} strokeWidth={20} /></Grid>
        <Grid container item xs={12} style={{ position: 'absolute', top: 70, left: 0 }}>
          <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}><p>{currentValue + unit}</p></Grid>
          <Grid xs={12} style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
            <p>{min}</p>
            <p>{max}</p>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const theme = createMuiTheme({ breakpoints: { values: { xl2: 1800, xl3: 2100 } } });

const useStyles = makeStyles(() => ({
  sizeGauge: {
    [theme.breakpoints.up('xl2')]: { transform: 'scale(1.5)' },
    [theme.breakpoints.up('xl3')]: { transform: 'scale(2)' },
  },
}));