import React from 'react'

export default function Alarm({ status }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
      <div
        className="position-relative rounded-circle shadow-lg"
        style={{
          width: 70,
          height: 70,
          backgroundColor: status % 2 === 0 ? '#EF373733' : '#00BF9133',
        }}
      >
        <div
          className="position-absolute rounded-circle shadow-lg"
          style={{
            width: 50,
            height: 50,
            left: '50%',
            marginLeft: -25,
            top: '50%',
            marginTop: -25,
            backgroundColor: status % 2 === 0 ? '#EF3737' : '#00BF91',
          }}
        />
      </div>
    </div>
  )
}