import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';

const LineGraph = ({ data, xMinMax }) => {
  return (
    <ResponsiveLine
      data={data}
      margin={{
        top: 20,
        right: 20,
        bottom: 60,
        left: 50,
      }}
      xScale={{
        type: 'linear',
        ...xMinMax,
      }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      // curve="linear"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: (value) => moment.unix(value).format('h:mm A'),
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 90,
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: 'middle',
        // tickValues: 7,
      }}
      enableGridX
      enableGridY
      enablePoints={false}
      enableArea={false}
      colors="#0F5CF2"
      lineWidth={3}
      useMesh
      gridYValues={7}
      theme={{
        background: 'transparent',
        axis: {
          fontSize: '15px',
          tickColor: '#eee',
          ticks: {
            line: {
              stroke: '#555555',
            },
            text: {
              fill: '#8898AA',
            },
          },
          legend: {
            text: {
              fill: '#aaaaaa',
            },
          },
        },
        grid: {
          line: {
            stroke: '#8898AA',
            strokeWidth: 1,
            strokeDasharray: '2',
          },
        },
      }}
    />
  );
};

export default LineGraph;
