import React, { useContext } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { AuthProvider, AuthContext } from '@Context/Auth';

import LoginForm from '@Pages/auth/LoginForm';
import RegisterForm from '@Pages/auth/RegisterForm';
import ResetPasswordForm from '@Pages/auth/ResetPasswordForm';
import Devices from '@Pages/Devices';
import Dashboard from '@Pages/Dashboard';
import DeviceDetails from '@Pages/DeviceDetails';
import Layout from '@Components/Layout';
import Logout from '@Pages/auth/Logout';
import useHook from './hook';

const App = () => {
  const h = useHook();
  console.log('hook', h);
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <LoginForm {...h} />
          </Route>
          <Route exact path="/register">
            <RegisterForm {...h} />
          </Route>
          <Route exact path="/reset-password">
            <ResetPasswordForm {...h} />
          </Route>
          <Route exact path="/logout">
            <Logout {...h} />
          </Route>
          <PrivateRoute exact path="/dashboard">
            <MainContainer child={<Dashboard {...h} />} />
          </PrivateRoute>
          <PrivateRoute exact path="/devices">
            <MainContainer child={<Devices {...h} />} />
          </PrivateRoute>
          <PrivateRoute exact path="/devices/:id">
            <MainContainer child={<DeviceDetails {...h} />} />
          </PrivateRoute>
        </Switch>
      </Router>
    </AuthProvider>
  );
};

function MainContainer({ child }) {
  return (
    <Layout child={child} />
  );
}

function PrivateRoute({ children, ...rest }) {
  const Auth = useContext(AuthContext);
  console.log('vv PrivateRoute', Auth)
  return (
    <Route
      {...rest}
      render={() => (Auth.token ? (children) : (<Redirect to={{ pathname: '/' }} />))}
    />
  );
}

export default App;
