import React from 'react';
import { Grid } from '@material-ui/core';
import GaugeChart from '@Components/GaugeChart';
import LineGraph from '@Components/LineGraph';
import _ from 'lodash';
import Alarm from './Alarm';
const unit = (type) => {
  switch (type) {
    case 'Temperature': return '°C';
    case 'Humidity': return '%';
    case 'PH': return 'Pa';
    case "Electrolyte Conductivity": return '%';
    case 'Rainfall': return 'mm';
    case 'Speed': return 'm/s';
    case 'Direction': return 'deg';
    case 'Pressure': return 'Pa';
    case "Lux": return '%';
    default: return '%';
  }
};
export default function SensorCard({ data, sensor, min, max }) {
  
  console.log('vv data',data)
  
  let currentValue = 0;
  if (data) {
    const allDataTime = [...Object.keys(data)];
    allDataTime.sort((a, b) => a - b);
    currentValue = data[allDataTime[allDataTime.length - 1]].value;
  }

  return (
    <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', margin: '15px 0' }}>
      <Grid item xs={3}>
        <div className="shadow" style={{ padding: 10, borderRadius: '10px', height: '100%' }}>
          <p style={{ fontSize: 20 }}>{sensor.name}</p>
          <GaugeChart currentValue={currentValue} min={Number(min) || 0} max={Number(max) || 100} unit={unit(sensor.type)}/>
        </div>
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', alignContent: 'stretch' }}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12}>
            <div className="shadow" style={{ padding: 10, borderRadius: '10px', height: '100%' }}>
              <p style={{ fontSize: 20 }}>{unit(sensor.type)}</p>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h3>{currentValue} {unit(sensor.type)}</h3>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="shadow" style={{ padding: 10, borderRadius: '10px', height: '100%' }}>
              <p style={{ fontSize: 20 }}>Alarm</p>
              <Alarm status={sensor.alarm_status || false} />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={7} >
        <div className="shadow" style={{ padding: 10, borderRadius: '10px', height: '100%' }}>
          <p style={{ fontSize: 20 }}>Data Over TIme</p>
          <div style={{ width: '100%', height: '12vh' }}>
            {data ? <LineGraph data={lineGenerator(data)} xMinMax={minMap} /> : "No Data"}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

const minMap = {
  "min": 1640232734,
  "max": 1640232992,
}
const lineGenerator = (data) => {
  const allDataTime = [...Object.keys(data)];
  allDataTime.sort((a, b) => a - b);
  return [{
    "data": allDataTime.map((t, idx) => ({ x: t, y: data[t].value })),
  }];
}
