import React, { useState } from 'react';
// import { Card } from 'reactstrap';
import Maps from '@Components/Map';
import useHook from './hook';
// import DeviceCard from '@Components/DeviceCard';
// import _ from 'lodash';

const Dashboard = (props) => {
//   const h = useHook(props);
  const [DevId, set_DevId] = useState(null);
  const [show_card, set_show_card] = useState(false);
//   console.log('TRIGGER IN DASHBOARD');
  return (
    <div className="position-relative">
      {/* {show_card
        ? (
          <Card
            className="position-absolute mb-0 h-full"
            style={{ zIndex: 1000, top: '6rem', left: '66vw', width: '22vw', background: 'none', border: 'none' }}
          >
            <DeviceCard device={_.find(h.sensors, { id: DevId })} index={0} />
          </Card>
        ) : null} */}
      <div style={{ width: '92vw', height: '94vh' }}>
        <Maps
          dashboard
          zoom={6}
          lat={2.159534}
          lng={109.301625}
          markers={[]}
          DevId={DevId}
          set_DevId={set_DevId}
          show_card={show_card}
          set_show_card={set_show_card}
        />
      </div>
    </div>
  );
};

export default Dashboard;
