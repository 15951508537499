import { Grid } from '@material-ui/core';
import React from 'react';

export default function DeviceInfo() {
  return (
    <div style={{ width: '100%' }}>
      <Grid container style={{ padding: '0 20px' }}>
        <Grid container item md={6}>
          {[
            { title: 'STATUS', value: 'Offline' },
            { title: 'LAST UPDATED', value: '6:29 AM Nov 18, 2021' },
            { title: 'LAST ONLINE', value: '12:26 AM Nov 18, 2021' },
            {
              title: 'LATEST METADATA UPDATE', value: '12:38 AM Nov 18, 2021 by utbah@ofo.my'
            },
            {
              title: 'DEVICE ACTIVATED', value: '12:26 AM Nov 18, 2021 by utbah@ofo.my'
            },
            { title: 'ORGANIZATION', value: 'OFO TECH - 6362WL' },
            { title: 'TOTAL ONLINE TIME', value: '6 hours 3 minutes' },
            {
              title: 'TEMPLATE NAME', value: 'OFOSense AGRO'
            },
            { title: 'IP', value: '110.159.103.154' },
            { title: 'MANUFACTURER', value: 'OFO TECH 6362WL' },
            {
              title: 'IP COUNTRY', value: 'Malaysia'
            },
            { title: 'IP LAT/LON', value: '3.0081/101.7719' },
          ].map(e => (
            <Grid item xs={6} style={{ marginBottom: 20 }}>
              <p style={{ marginBottom: 3, fontSize: 12 }}>{e.title}</p>
              <p style={{ fontSize: 16 }}>{e.value}</p>
            </Grid>
          ))}
        </Grid>
        <Grid md={6}>
          FIRMWARE CONFIGURATION
          <div style={{ borderRadius: '10px', backgroundColor: '#000000', marginTop: 5 }}>
            {[
              { label: '#define BLYNK_TEMPLATE_ID', text: 'TMPL_9VBaj8D' },
              { label: '#define BLYNK_DEVICE_NAME', text: 'OFOSense AGRO' },
              { label: '#define BLYNK_AUTH_TOKEN', text: 'JziXde4LmdzzKMyLhQUtEKIISyUcKP6c' },
            ].map(e => (
              <Grid container item style={{ marginBottom: 5, padding: 10 }}>
                <Grid item style={{ fontSize: 16, color: '#faf3e1', marginRight: 5 }}>
                  {e.label}
                </Grid>
                <Grid item style={{ fontSize: 16, color: '#8fcf7f' }}>
                  {e.text}
                </Grid>
              </Grid>
            ))}
          </div>
          Template ID, Device Name, and AuthToken should be declared at the very top of the firmware code.
        </Grid>
      </Grid>
    </div>
  );
}
