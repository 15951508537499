export const URL = process.env.REACT_APP_ENDPOINT_URL;
export const endpoints = {
  getStaticData: () => ['GET', `${URL}staticdata`],
  getToken: () => ['POST', `${URL}login`],
  register: () => ['POST', `${URL}signup`],
  getProfile: () => ['GET', `${URL}api/profile`],
  getDeviceGroup: () => ['GET', `${URL}api/devicegroup`],
  getSensors: (id) => ['GET', `${URL}api/devicegroup/${id}`],
  registerDeviceGroup: () => ['POST', `${URL}api/devicegroup`],
  
  requestTokenToResetPassword: () => ['POST', `${URL}forgot-password`],
  verifyResetPasswordToken: () => ['POST', `${URL}verify-reset-password-token`],
  resetPassword: () => ['POST', `${URL}reset-password`],
  activityLog: () => ['GET', `${URL}api/activitylog`],

  getPython: () => ['POST', `${URL}api/python-generator`],
};
